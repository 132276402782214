import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { useRedemptionContext } from '../../hooks/useRedemptionContext';
import { type HeaderContext } from '../../types';
import { RedeemButtonDefault } from './RedeemButtonDefault';
import { RedeemButtonWithBalance } from './RedeemButtonWithBalance';

const RedeemButton = ({ layoutClassName }: { readonly layoutClassName?: string }): JSX.Element => {
    const { featureFlagsVariation, tenantConfig }: HeaderContext = useFragmentContext();
    const { redemptionInformation } = useRedemptionContext();

    const oneVoucherRedemption = featureFlagsVariation?.redemption_entry_visits === 'on';
    const redemptionUrl = oneVoucherRedemption ? `/einloesen` : tenantConfig.urls.redemption;

    return redemptionInformation?.isEnabled ? (
        <RedeemButtonWithBalance
            layoutClassName={layoutClassName}
            vouchersTotal={redemptionInformation.vouchersTotal}
        />
    ) : (
        <RedeemButtonDefault layoutClassName={layoutClassName} redemptionUrl={redemptionUrl} />
    );
};

export { RedeemButton };
