import { useEffect, useRef, useState } from 'react';

import { isSSR } from '@jsmdg/yoshi';

type UseIntersectionProps = {
    root?: Element | Document | null;
    rootMargin?: string;
    threshold: number;
};

export function useIntersection({
    root,
    rootMargin,
    threshold,
}: UseIntersectionProps): [boolean, (element: HTMLAnchorElement | HTMLDivElement | null) => void] {
    const [targetElement, setTargetElement] = useState<HTMLAnchorElement | HTMLDivElement | null>();
    const [isIntersecting, setIsIntersecting] = useState(false);

    const intersectionObserver = useRef<IntersectionObserver | null>(null);

    useEffect(() => {
        if (!isSSR && 'IntersectionObserver' in window) {
            intersectionObserver.current = new IntersectionObserver(
                ([entry]) => setIsIntersecting(entry.isIntersecting),
                {
                    root,
                    rootMargin,
                    threshold,
                },
            );
            if (targetElement) {
                intersectionObserver.current.observe(targetElement);
            }
        }

        return function disconnectIntersectionObserver() {
            intersectionObserver.current?.disconnect();
        };
    }, [root, rootMargin, setIsIntersecting, targetElement, threshold]);

    return [isIntersecting, setTargetElement];
}
