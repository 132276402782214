import axios, { type AxiosResponse } from 'axios';

import { type CartPreview } from '../../shared/types';

async function fetchCart(): Promise<CartPreview> {
    const response: AxiosResponse<CartPreview> = await axios.get('/api/cart');
    return response.data;
}

export { fetchCart };
