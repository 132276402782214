import classnames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type HeaderContext } from '../../types';
import { CheckoutStep } from '../CheckoutStep/CheckoutStep';
import styles from './CheckoutSteps.module.scss';

type CheckoutStepsProps = {
    readonly step: number;
};

const CheckoutSteps = ({ step }: CheckoutStepsProps): JSX.Element => {
    const { cartUrl, checkoutUrl } = useFragmentContext<HeaderContext>();

    return (
        <div className={classnames('w-100 d-flex justify-content-between', styles.checkoutSteps)}>
            <div className={classnames('d-none d-md-flex mt-md-1x', styles.leftSpace)} />
            <div className={classnames('d-flex w-100 mt-1x', styles.stepWrapper)}>
                <CheckoutStep step={1} activeStep={step} titleId="stepOne" stepUrl={cartUrl} />
                <CheckoutStep step={2} activeStep={step} titleId="stepTwo" stepUrl={checkoutUrl} />
                <CheckoutStep step={3} activeStep={step} titleId="stepThree" stepUrl={undefined} />
            </div>
            <div
                className={classnames('d-none d-md-flex mt-md-1x', styles.rightSpace, {
                    [styles.active]: step === 3,
                })}
            />
        </div>
    );
};

export { CheckoutSteps };
