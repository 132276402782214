import {
    type ClickEventLabel,
    type ClickEventType,
    GA4EventName,
    GA4FeatureCategory,
    trackCustomerAccountClick,
} from '@jsmdg/tracking';

const trackCustomerAccount = async (
    clickEventType: ClickEventType,
    clickEventLabel: ClickEventLabel,
    url: string,
    clickElement: string,
    clickText?: string,
): Promise<void> => {
    await window.yieldToMainThread();
    trackCustomerAccountClick(clickEventType, clickEventLabel, {
        eventName: GA4EventName.ClickNavigationItem,
        feature_category: GA4FeatureCategory.HeaderNavigation,
        click_url: url,
        click_element: clickElement,
        ...(clickText ? { click_text: clickText } : undefined),
    });
};

export { trackCustomerAccount };
