import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import {
    ClickEventLabel,
    EventAction,
    EventCategory,
    GA4EventName,
    GA4FeatureCategory,
    trackAnalyticsEvent,
} from '@jsmdg/tracking';
import {
    Badge,
    BadgeColor,
    BadgeSize,
    Button,
    ButtonColor,
    ButtonShape,
    ButtonSize,
    ButtonVariant,
    RenderType,
    ShoppingCartIcon,
} from '@jsmdg/yoshi';
import { getNumberOfCartItems } from '../../helper/getNumberOfCartItems';
import { useCartContext } from '../../hooks/useCartContext';
import { type HeaderContext } from '../../types';
import styles from '../common/common_styles.module.scss';

const messages = defineMessages({
    addToCart: {
        defaultMessage: 'Zum Warenkorb',
    },
    itemsInCartA11yText: {
        defaultMessage: 'Artikel im Warenkorb',
    },
});

type CartButtonProps = {
    readonly layoutClassName?: string;
};

const CartButton = ({ layoutClassName }: CartButtonProps): JSX.Element => {
    const Intl = useIntl();
    const { createCartButtonClickedEvent } = useFragmentContext<HeaderContext>();
    const cart = useCartContext();
    const numberOfCartItems = getNumberOfCartItems(cart?.itemsCount);

    const onCartClick = async (): Promise<void> => {
        await window.yieldToMainThread();
        trackAnalyticsEvent({
            category: EventCategory.Header,
            action: EventAction.Click,
            label: ClickEventLabel.CART,
            nonInteraction: false,
            eventData: {
                eventName: GA4EventName.ClickNavigationItem,
                feature_category: GA4FeatureCategory.HeaderNavigation,
                click_element: numberOfCartItems ? 'Cart filled' : 'Cart unfilled',
                click_url: `${window.location.origin}/cart`,
            },
        });
        createCartButtonClickedEvent();
    };

    return (
        <Button
            as={RenderType.Link}
            size={ButtonSize.Small}
            variant={ButtonVariant.Ghost}
            color={ButtonColor.Dark}
            shape={ButtonShape.Icon}
            href="/cart"
            onClick={onCartClick}
            className={classNames(layoutClassName, 'position-relative')}
            a11yText={Intl.formatMessage(messages.addToCart)}
        >
            <ShoppingCartIcon />
            {numberOfCartItems ? (
                <Badge
                    data-testid="cart-badge"
                    className={classNames('position-absolute', styles.badge)}
                    size={BadgeSize.Small}
                    color={BadgeColor.Complementary}
                    isRounded
                >
                    <span className="screenReaderOnly">
                        {Intl.formatMessage(messages.itemsInCartA11yText)}
                    </span>
                    {numberOfCartItems}
                </Badge>
            ) : null}
        </Button>
    );
};

export { CartButton };
