import {
    GA4EventName,
    GA4FeatureCategory,
    PromotionType,
    trackAnalyticsEvent,
    trackEecPromotionClick,
    trackEecPromotionImpression,
} from '@jsmdg/tracking';
import { Banner, Wysiwyg } from '@jsmdg/yoshi';
import { BannerType } from '@jsmdg/yoshi/dist/enums/banner';
import { type CampaignCreative } from '../../../../shared/types/campaignCreative';
import { useImpressionTracking } from '../../../hooks/useImpressionTracking';
import styles from './HeaderBanner.module.scss';

type HeaderBannerProps = {
    readonly creative: CampaignCreative;
};

const getModalInfo = (creative: CampaignCreative) => {
    const { body, iconColor, title } = creative?.asterisk || {};
    return body
        ? {
              headline: <Wysiwyg content={title || ''} />,
              infoContent: <Wysiwyg content={body} />,
              infoIconColor: iconColor || '',
              a11yCloseText: title || '',
          }
        : undefined;
};

const HeaderBanner = ({ creative }: HeaderBannerProps): JSX.Element | null => {
    const { primaryColor, secondaryColor, text, textColor } = creative;
    const position = 'vertical1_horizontal1';

    const promotionTrackingPayload = {
        id: creative.trackingName,
        name: creative.campaignName,
        creative: creative.placeholderId,
        position,
    };

    const promotionTrackingEventData = {
        creativeName: 'Header_Banner',
        creativeSlot: position,
        promotionName: creative.campaignName,
        promotionId: creative.trackingName,
        locationId: creative.url || '',
        promotionType: PromotionType.Dynamic,
        promotionCountdown: `${!!creative.countdown}`,
        promotionPlaceholder: creative.placeholderId,
    };

    const setImpressionTrackedElement = useImpressionTracking(() =>
        trackEecPromotionImpression([promotionTrackingPayload], promotionTrackingEventData),
    );

    return (
        <div
            style={
                {
                    '--textColor': textColor,
                } as React.CSSProperties
            }
            ref={setImpressionTrackedElement}
        >
            <Banner
                type={BannerType.TextBanner}
                text={text}
                textColor={textColor}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                modalInfos={getModalInfo(creative)}
                className={styles.headerBanner}
                onInfoClick={() =>
                    trackAnalyticsEvent({
                        eventData: {
                            eventName: GA4EventName.ClickButton,
                            feature_category: GA4FeatureCategory.PromotionDynamic,
                            creative_name: 'Header-banner',
                            click_element: 'Info icon',
                        },
                    })
                }
                link={creative.url}
                onClick={() => {
                    trackEecPromotionClick(promotionTrackingPayload, promotionTrackingEventData);
                }}
            />
        </div>
    );
};

export { HeaderBanner };
