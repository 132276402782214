import { type KeyboardEvent, type MouseEvent, type UIEvent } from 'react';

import { type GA4EventName, type GA4FeatureCategory, trackAnalyticsEvent } from '@jsmdg/tracking';
import { type GA4ClickEvent } from '@jsmdg/tracking/dist/events';
import { type NavigationItem } from '../../shared/types';

type NavigationClickProps = {
    category: string;
    action: string;
    e?: UIEvent | KeyboardEvent | MouseEvent<HTMLAnchorElement>;
    label?: string;
    item?: NavigationItem;
    navigationItems?: NavigationItem[];
    eventData?: GA4ClickEvent;
};

export type BreadcrumbClickProps = {
    e?: UIEvent | KeyboardEvent | MouseEvent<HTMLAnchorElement>;
    action: string;
    label: string;
    eventData: {
        eventName: GA4EventName;
        feature_category: GA4FeatureCategory;
        click_element: string;
        click_text: string;
        navigationLevel: number;
        click_url: string;
    };
};

export function getTrackingLabel(
    navTrackingLabel: string,
    parentNavTrackingLabel?: string,
): string {
    return parentNavTrackingLabel
        ? `${parentNavTrackingLabel}_${navTrackingLabel}`
        : navTrackingLabel;
}

export const trackingCategories = {
    TOP_NAVI: 'TopNavi',
    BURGER_NAVI: 'BurgerNavi',
};

export const availableTrackingActions = {
    CLICK: 'Click',
    BUTTON_CLICK: 'ButtonClick',
    OPEN: 'Open',
    BACK: 'Back',
    CLOSE: 'Close',
    HOVER_IN: 'HoverIn',
};

export function trackNavigationClick({
    e,
    category,
    action,
    label,
    eventData,
}: NavigationClickProps): void {
    e?.stopPropagation();

    trackAnalyticsEvent({
        category,
        action,
        label,
        eventData,
    });
}

export function trackBreadcrumbClick({ e, action, label, eventData }: BreadcrumbClickProps): void {
    e?.stopPropagation();

    trackAnalyticsEvent({
        category: 'Breadcrumb',
        action,
        label,
        eventData,
    });
}

export function trackTopNavigationHover(
    action: string,
    item: NavigationItem,
    label?: string,
    eventData?: GA4ClickEvent,
): void {
    trackAnalyticsEvent({
        category: trackingCategories.TOP_NAVI,
        action,
        label,
        eventData,
    });
}
