import { useState } from 'react';
import { useIsSSR } from 'react-aria';
import classnames from 'classnames';

import { AlgoliaContextProvider } from '@jsmdg/algolia-helpers';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Box } from '@jsmdg/yoshi';
import { useAlgoliaClient } from '../../hooks/useAlgoliaClient';
import { type HeaderContext } from '../../types';
import { ActionHeader } from '../ActionHeader/ActionHeader';
import { Backdrop } from '../Backdrop';
import { BreadcrumbMarkup } from '../BreadcrumbMarkup/BreadcrumbMarkup';
import { CartContextProvider } from '../CartContextProvider';
import { DesktopNavigation } from '../DesktopNavigation/DesktopNavigation';
import { HeaderBreadcrumbs } from '../HeaderBreadcrumbs';
import { OrganisationMarkup } from '../OrganisationMarkup';
import { RedemptionContextProvider } from '../RedemptionContextProvider';
import { TopHeader } from '../TopHeader/TopHeader';
import styles from './StoreHeader.module.scss';

const StoreHeader = (): JSX.Element => {
    const [fullscreenOpen, setFullscreenOpen] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const { breadcrumbs, showBreadcrumbs }: HeaderContext = useFragmentContext();
    const isSSR = useIsSSR();
    const searchClient = useAlgoliaClient();

    const canRenderBreadcrumbMarkup = !isSSR && !!breadcrumbs;
    const canRenderBreadcrumbs = showBreadcrumbs && !!breadcrumbs;

    return (
        <>
            <Box className={classnames('w-100', styles.storeHeader)}>
                <header className={classnames(styles.header, fullscreenOpen && styles.aboveNav)}>
                    <CartContextProvider>
                        <TopHeader />
                        <AlgoliaContextProvider client={searchClient}>
                            <RedemptionContextProvider>
                                <ActionHeader onFullscreenChange={setFullscreenOpen} />
                            </RedemptionContextProvider>
                        </AlgoliaContextProvider>
                        <DesktopNavigation toggleBackdrop={setShowBackdrop} />
                    </CartContextProvider>
                </header>
                <Backdrop showBackdrop={showBackdrop} />
            </Box>
            {canRenderBreadcrumbs && <HeaderBreadcrumbs />}
            {!isSSR && <OrganisationMarkup url={window.location.origin} />}
            {canRenderBreadcrumbMarkup && <BreadcrumbMarkup markupData={breadcrumbs} />}
        </>
    );
};

export { StoreHeader };
