import { type MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import {
    ClickEventLabel,
    EventAction,
    EventCategory,
    GA4EventName,
    GA4FeatureCategory,
    trackAnalyticsEvent,
} from '@jsmdg/tracking';
import { PureLink } from '@jsmdg/yoshi';
import { getThemeConfig } from '../../helper/themeUtils';
import { type HeaderContext } from '../../types';
import styles from './Logo.module.scss';

type LogoProps = {
    readonly href?: string;
    readonly className?: string;
    readonly isOnMdBreakpoint?: boolean;
};

const Logo = ({ className, href, isOnMdBreakpoint }: LogoProps): JSX.Element => {
    const { tenant } = useFragmentContext<HeaderContext>();

    const intl = useIntl();

    const themeConfig = getThemeConfig(tenant, intl);

    const logoClassName = classNames(className, 'd-inline-block', styles.logo, {
        [styles.isOnMdBreakpoint]: isOnMdBreakpoint,
        [styles.isOnSmBreakpoint]: !isOnMdBreakpoint,
    });

    if (!href) {
        return <div className={logoClassName}>{themeConfig.logo}</div>;
    }

    const onLogoClick = async (e: MouseEvent): Promise<void> => {
        e.preventDefault();
        await window.yieldToMainThread();
        trackAnalyticsEvent({
            category: EventCategory.Header,
            action: EventAction.Click,
            label: ClickEventLabel.LOGO,
            nonInteraction: false,
            eventData: {
                eventName: GA4EventName.ClickNavigationItem,
                feature_category: GA4FeatureCategory.HeaderNavigation,
                click_element: 'Logo',
                click_url: window.location.origin,
            },
        });

        window.location.href = href;
    };

    return (
        <PureLink internal href={href} className={logoClassName} onClick={onLogoClick}>
            {themeConfig.logo}
        </PureLink>
    );
};

export { Logo };
