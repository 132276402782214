import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { type NavigationItem } from '../../../../shared/types';
import { getTrackingLabel } from '../../../helper/trackNavigation';
import { useActivateListItemWithDelay } from '../../../hooks/useActivateListItemWithDelay';
// eslint-disable-next-line import/no-cycle
import { DesktopNavigationItem } from '../DesktopNavigationItem/DesktopNavigationItem';
import styles from './DesktopNavigationGroup.module.scss';

const messages = defineMessages({
    selfReferrerTitle: {
        defaultMessage: 'Alles in {title}',
    },
});

function hasSelfReferringItem(group: NavigationItem, depth: number, maxDepth: number): boolean {
    return !!(group.uri && group?.children?.length && depth <= maxDepth);
}

type DesktopNavigationGroupProps = {
    readonly group: NavigationItem;
    readonly depth: number;
    readonly maxDepth: number;
    readonly isLastLevel?: boolean;
    readonly className?: string;
    readonly parentTrackingLabel?: string;
};

const DesktopNavigationGroup = ({
    className = '',
    depth,
    group,
    isLastLevel = false,
    maxDepth,
    parentTrackingLabel,
}: DesktopNavigationGroupProps): JSX.Element => {
    const intl = useIntl();
    const { activeItemIndex, resetActiveItemIndexWithDelay, setActiveItemIndexWithDelay } =
        useActivateListItemWithDelay();
    const lastLevelClassNames = 'position-absolute top-0 start-100 w-100 h-100 m-0';
    const groupClassNames = 'py-1x pl-2x';
    return (
        <ul
            className={classNames(className, styles.group, groupClassNames, {
                [lastLevelClassNames]: isLastLevel,
                [styles.navigationGroup]: !isLastLevel,
                [styles.scrollableNavigationGroup]:
                    depth > 1 && group.children && group.children.length > 10,
            })}
        >
            {hasSelfReferringItem(group, depth, maxDepth) && depth !== 0 && (
                <DesktopNavigationItem
                    item={{
                        id: group.id,
                        uri: group.uri,
                        title: intl.formatMessage(messages.selfReferrerTitle, {
                            title: group.title,
                        }),
                    }}
                    depth={depth + 1}
                    maxDepth={maxDepth}
                    isSelfReferrer
                    trackingLabel={getTrackingLabel(group.title as string, parentTrackingLabel)}
                />
            )}
            {group?.children?.map((item, index) => (
                <DesktopNavigationItem
                    item={item}
                    key={item.id}
                    depth={depth + 1}
                    maxDepth={maxDepth}
                    isActive={activeItemIndex === index}
                    handleEnterItem={() => {
                        setActiveItemIndexWithDelay(index);
                    }}
                    handleLeaveItem={resetActiveItemIndexWithDelay}
                    isSelfReferrer={index === 0 && hasSelfReferringItem(group, depth, maxDepth)}
                    trackingLabel={getTrackingLabel(item.title as string, parentTrackingLabel)}
                />
            ))}
        </ul>
    );
};

export { DesktopNavigationGroup };
