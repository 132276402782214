import { getAlgoliaIndex, type ObjectType, useAlgoliaResults } from '@jsmdg/algolia-helpers';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type Page, type Product } from '../../shared/types';
import { AlgoliaQueryId } from '../enums/AlgoliaQueryId';
import { type HeaderContext } from '../types';
import { useRedemptionContext } from './useRedemptionContext';

const DefaultSearchResultSize = 5;
type UseSearchResultsParams = {
    objectType: ObjectType;
    searchTerm: string;
    size?: number;
    attributesToRetrieve?: string[];
    attributesToHighlight?: string[];
};

const useSearchResults = <T extends Product | Page>({
    objectType,
    searchTerm,
    size,
    attributesToRetrieve,
    attributesToHighlight,
}: UseSearchResultsParams): { indexName: string; results: T[]; queryId?: string } => {
    const { algoliaConfig, tenant, locale } = useFragmentContext<HeaderContext>();
    const { redemptionInformation } = useRedemptionContext();
    const indexName = getAlgoliaIndex({
        locale,
        tenant,
        objectType,
        environment: algoliaConfig.environment,
    });

    const isRedemptionFlow =
        redemptionInformation?.isEnabled && redemptionInformation?.vouchersCount > 0;

    const { results, queryId } = useAlgoliaResults<T>({
        indexName,
        objectType,
        search: {
            searchTerm,
            pagination: {
                offset: 0,
                size: size || DefaultSearchResultSize,
                currentPage: 1,
            },
            filter: {
                isShoppingFlow: !isRedemptionFlow,
            },
        },
        id: `${objectType}-${AlgoliaQueryId.Search}`,
        // NOTE: Important so we don't initiate a search until user starts typing
        initialState: [],
        options: {
            attributesToRetrieve,
            attributesToHighlight,
            highlightPreTag: `<span class="fw-regular">`,
            highlightPostTag: '</span>',
            userToken: algoliaConfig.userToken,
        },
    });

    return { results, indexName, queryId };
};

export { useSearchResults };
