export enum CategoryIcon {
    Giftbox = 'giftbox',
    ValueVoucher = 'valueVoucher',
    Travel = 'travel',
    Flying = 'flying',
    Motorpower = 'motorpower',
    Dinner = 'dinner',
    WaterSports = 'waterSports',
    Wellness = 'wellness',
}
