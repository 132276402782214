import { JsonLd } from 'react-schemaorg';
import { type BreadcrumbList, type WithContext } from 'schema-dts';

import { type BreadCrumbItemInterface } from '../../../shared/types';

type BreadcrumbMarkupProps = {
    readonly markupData: BreadCrumbItemInterface[];
};

const BreadcrumbMarkup = ({ markupData }: BreadcrumbMarkupProps): JSX.Element => {
    const { location } = window;

    const breadcrumbMarkupItems = [
        {
            title: location.hostname,
            url: null,
        },
        ...markupData,
    ];

    const markup: WithContext<BreadcrumbList> = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbMarkupItems.map((singleBreadcrumbEntry, index) => {
            const urlPath = [location.origin, singleBreadcrumbEntry.url].join('');
            const position = index + 1;
            return {
                '@type': 'ListItem',
                position,
                item: {
                    '@id': urlPath,
                    name: singleBreadcrumbEntry.title,
                },
            };
        }),
    };

    return <JsonLd item={markup} />;
};

export { BreadcrumbMarkup };
