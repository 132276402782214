import { useContext } from 'react';

import { type CartPreview } from '../../shared/types';
import { CartContextProvider } from '../components/CartContextProvider/CartContextProvider';
import { CartContext } from '../context/CartContext';

const useCartContext = (): CartPreview | null => {
    const context = useContext(CartContext);

    if (context === undefined) {
        throw new Error(`${useCartContext.name} must be used within a ${CartContextProvider.name}`);
    }

    return context;
};

export { useCartContext };
