/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
const OP_CODE_DELETION = 'DELETE';
const OP_CODE_WRITING = 'WRITING';

const splitter = (text: string): readonly string[] => [...text];

function getOverlap(start: string, [...end]: string): number {
    return [...start, Number.NaN].findIndex((char, i) => end[i] !== char);
}

function* writer(text: string, startIndex = 0): IterableIterator<string> {
    const splitText = splitter(text);
    const endIndex = splitText.length;
    let spliceEndIndex = startIndex;

    while (spliceEndIndex < endIndex) {
        spliceEndIndex += 1;
        yield splitText.slice(0, spliceEndIndex).join('');
    }
}

function* deleter(text: string, startIndex = 0): IterableIterator<string> {
    const splitText = splitter(text);
    const endIndex = splitText.length;
    let spliceEndIndex = endIndex;

    while (spliceEndIndex > startIndex) {
        spliceEndIndex -= 1;
        yield splitText.slice(0, spliceEndIndex).join('');
    }
}

function* editor(edits: readonly string[]): IterableIterator<{
    op: (node: HTMLInputElement) => number;
    opCode: (node: HTMLInputElement) => string;
}> {
    for (const snippet of edits) {
        yield {
            op: (node: HTMLInputElement) =>
                requestAnimationFrame(() => {
                    node.setAttribute('placeholder', snippet);
                }),
            opCode: (node: HTMLInputElement) => {
                const nodeContent = node.getAttribute('placeholder') || '';

                return snippet === '' || nodeContent.length > snippet.length
                    ? OP_CODE_DELETION
                    : OP_CODE_WRITING;
            },
        };
    }
}

async function wait(ms: number): Promise<void> {
    await new Promise<void>(resolve => {
        setTimeout(resolve, ms);
    });
}

async function perform(
    node: HTMLInputElement,
    edits: readonly string[],
    speed: number,
    deletionSpeed: number,
): Promise<void> {
    for (const op of editor(edits)) {
        const waitingTime =
            op.opCode(node) === OP_CODE_WRITING
                ? speed + speed * (Math.random() - 0.5)
                : deletionSpeed + deletionSpeed * (Math.random() - 0.5);
        op.op(node);
        await wait(waitingTime);
    }
}

async function edit(
    node: HTMLInputElement,
    text: string,
    speed: number,
    deletionSpeed: number,
): Promise<void> {
    const nodeContent = node.getAttribute('placeholder') || '';

    const overlap = getOverlap(nodeContent, text);
    await perform(
        node,
        [...deleter(nodeContent, overlap), ...writer(text, overlap)],
        speed,
        deletionSpeed,
    );
}

export async function type(
    node: HTMLInputElement,
    speed: number,
    deletionSpeed: number,
    ...args: Array<string | number | typeof type>
): Promise<void> {
    for (const arg of args) {
        switch (typeof arg) {
            case 'string':
                await edit(node, arg, speed, deletionSpeed);
                break;
            case 'number':
                await wait(arg);
                break;
            case 'function':
                // this causes an infinite, recursive call-loop here
                await arg(node, speed, deletionSpeed, ...args);
                break;
            default:
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                arg;
        }
    }
}
