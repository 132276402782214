import { getSearchHistoryStorage } from '@jsmdg/browser-storage';
import { logToSentry } from '@jsmdg/react-fragment-scripts/fragment';
import { type SearchHistoryItem } from '../../shared/types';
import { isQuotaExceededError } from './isQuotaExceededError';

const getSearchHistory = (): SearchHistoryItem[] => {
    const searchHistory = getSearchHistoryStorage().get();

    if (!searchHistory) {
        return [];
    }

    return JSON.parse(searchHistory) as SearchHistoryItem[];
};

const saveSearchHistory = (searchHistory: SearchHistoryItem[]): void => {
    try {
        getSearchHistoryStorage().set(JSON.stringify(searchHistory));
    } catch (error_) {
        const error = error_ as Error;
        if (isQuotaExceededError(error)) {
            logToSentry(`${error.message}; unable to set SearchHistory`);
        }
    }
};

const clearSearchHistory = (): void => {
    getSearchHistoryStorage().set('');
};

export { clearSearchHistory, getSearchHistory, saveSearchHistory };
