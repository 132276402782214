import { useCreatives } from '../../hooks/useCreatives';
import { HeaderBanner } from './HeaderBanner/HeaderBanner';

const Creative = (): JSX.Element | null => {
    const { hasCampaigns, headerBanner } = useCreatives();

    if (!hasCampaigns || !headerBanner || !headerBanner.text) {
        return null;
    }

    return <HeaderBanner creative={headerBanner} />;
};

export { Creative };
