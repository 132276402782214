import { createContext, type Dispatch, type SetStateAction } from 'react';

import { type RedemptionInformation } from '../../shared/types';

const RedemptionContext = createContext<{
    redemptionInformation: RedemptionInformation | null;
    setRedemptionInformation: Dispatch<SetStateAction<RedemptionInformation>>;
}>({ redemptionInformation: null, setRedemptionInformation: () => null });

export { RedemptionContext };
