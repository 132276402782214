import { JsonLd } from 'react-schemaorg';
import { type Organization, type WithContext } from 'schema-dts';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { getLogoUrl } from '../../helper/themeUtils';
import { type HeaderContext } from '../../types';

type OrganisationMarkupProps = {
    readonly url: string;
};

const OrganisationMarkup = ({ url }: OrganisationMarkupProps): JSX.Element => {
    const { tenant, tenantConfig }: HeaderContext = useFragmentContext();
    const organisationSameAs = [
        tenantConfig.urls.socialMedia.facebook,
        tenantConfig.urls.socialMedia.instagram,
        ...(tenantConfig.urls.socialMedia.twitter ? [tenantConfig.urls.socialMedia.twitter] : []),
        tenantConfig.urls.socialMedia.youtube,
        tenantConfig.urls.socialMedia.pinterest,
    ];

    const markup: WithContext<Organization> = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: tenantConfig.organisationName,
        url,
        logo: getLogoUrl(tenant),
        address: tenantConfig.organisationAddress,
        contactPoint: {
            '@type': 'ContactPoint',
            contactType: 'customer support',
            email: tenantConfig.contactInfo.serviceEmail,
            telephone: `[${tenantConfig.contactInfo.phone.number}]`,
            availableLanguage: tenantConfig.organisationAvailableLanguages,
        },
        sameAs: organisationSameAs,
        founder: tenantConfig.organisationFounder,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    return <JsonLd item={markup} />;
};

export { OrganisationMarkup };
