import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { CreativePlaceholderId } from '../../shared/enums/creativePlaceholderId';
import { type CampaignCreative } from '../../shared/types/campaignCreative';
import { type HeaderContext } from '../types';

type UseCreatives = {
    hasCampaigns: boolean;
    headerBanner?: CampaignCreative;
};

const getCreativeByPlaceholder = (
    creatives: CampaignCreative[] | undefined,
    placeholderId: CreativePlaceholderId,
): CampaignCreative | undefined =>
    creatives?.find(creative => creative.placeholderId === placeholderId);

export const useCreatives = (): UseCreatives => {
    const { campaignCreatives } = useFragmentContext<HeaderContext>();

    if (!campaignCreatives?.length) {
        return { hasCampaigns: false };
    }

    return {
        hasCampaigns: true,
        headerBanner: getCreativeByPlaceholder(
            campaignCreatives,
            CreativePlaceholderId.HeaderBanner,
        ),
    };
};
