type DebouncedAction<T extends unknown[]> = (...args: { [I in keyof T]: T[I] }) => void;

const debounce = <T extends unknown[]>(
    fn: DebouncedAction<T>,
    time: number,
): DebouncedAction<T> => {
    let timerId: ReturnType<typeof setTimeout> | undefined;

    return (...args: { [I in keyof T]: T[I] }): void => {
        if (timerId) {
            clearTimeout(timerId);
        }

        timerId = setTimeout(() => fn(...args), time);
    };
};

export { debounce };
