const getLinkWithLocationParams = (uri: string): string => {
    if (uri.includes('https://')) {
        return uri;
    }

    const currentUrlSearchParams = new URLSearchParams(window.location.search);
    const location: Record<string, string | null> = {
        lat: currentUrlSearchParams.get('lat'),
        long: currentUrlSearchParams.get('long'),
        locationName: currentUrlSearchParams.get('locationName'),
        distance: currentUrlSearchParams.get('distance'),
    };

    return Object.values(location).every(Boolean)
        ? `${uri}?${new URLSearchParams(location as Record<string, string>).toString()}`
        : uri;
};

export { getLinkWithLocationParams };
