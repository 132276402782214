import { defineMessages } from 'react-intl';
import { type IntlShape } from 'react-intl/src/types';
import classNames from 'classnames';

import { ReactComponent as JSLogo } from '../assets/jochen-schweizer.svg';
import { ReactComponent as MDLogo } from '../assets/mydays.svg';
import { type ThemeMetaData } from '../types';

const JSLogoUrl =
    'https://s.jochen-schweizer.de/webshop/production/logo/jochen_schweizer_logo_mobile.svg';

const MDLogoUrl = 'https://s.jochen-schweizer.de/webshop/production/logo/mydLogo.svg';

const messages = defineMessages({
    MDLogoAltText: {
        defaultMessage: 'Geschenke & Erlebnisse - Geschenkideen | mydays',
    },
    JSLogoAltText: {
        defaultMessage: 'Geschenke und Geschenkideen bei Jochen Schweizer',
    },
});

const mdConfig = (intl: IntlShape, logoClasses?: string): ThemeMetaData => ({
    logo: (
        <MDLogo
            className={classNames(logoClasses)}
            title={intl.formatMessage(messages.MDLogoAltText)}
        />
    ),
    url: MDLogoUrl,
});

const jsConfig = (intl: IntlShape, logoClasses?: string): ThemeMetaData => ({
    logo: (
        <JSLogo
            className={classNames(logoClasses)}
            title={intl.formatMessage(messages.JSLogoAltText)}
        />
    ),
    url: JSLogoUrl,
});

const getLogoUrl = (tenant: string): string => {
    const company = tenant.slice(0, 2);
    switch (company) {
        case 'JS':
            return JSLogoUrl;
        case 'MD':
            return MDLogoUrl;
        default:
            return JSLogoUrl;
    }
};

const getThemeConfig = (tenant: string, intl: IntlShape, logoClasses?: string): ThemeMetaData => {
    const theme = tenant.slice(0, 2);
    switch (theme) {
        case 'MD':
            return mdConfig(intl, logoClasses);
        case 'JS':
        default:
            return jsConfig(intl, logoClasses);
    }
};

export { getLogoUrl, getThemeConfig, JSLogoUrl, MDLogoUrl };
