import { type PropsWithChildren } from 'react';

import { CartContext } from '../../context/CartContext';
import { useCart } from '../../hooks/useCart';

const CartContextProvider = ({ children }: PropsWithChildren): JSX.Element => {
    const cart = useCart();
    return <CartContext.Provider value={cart}>{children}</CartContext.Provider>;
};

export { CartContextProvider };
