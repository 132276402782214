import { defineMessages, useIntl } from 'react-intl';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import {
    ClickEventLabel,
    EventCategory,
    GA4EventName,
    GA4FeatureCategory,
    trackAnalyticsEvent,
} from '@jsmdg/tracking';
import { Image, Link, LinkVariant, PaybackLogoSrc } from '@jsmdg/yoshi';
import { type HeaderContext } from '../../types';

const messages = defineMessages({
    payback: {
        defaultMessage: 'PAYBACK',
    },
});

const PaybackLink = (): JSX.Element => {
    const { tenantConfig } = useFragmentContext<HeaderContext>();
    const intl = useIntl();

    const trackClick = async (): Promise<void> => {
        await window.yieldToMainThread();
        trackAnalyticsEvent({
            category: EventCategory.Navigation,
            action: 'Header',
            label: ClickEventLabel.PAYBACK,
            eventData: {
                eventName: GA4EventName.ClickNavigationItem,
                feature_category: GA4FeatureCategory.HeaderNavigation,
                click_element: 'Payback',
                click_text: 'Payback',
                click_url: `${window.location.origin}${tenantConfig.urls.payback}`,
            },
        });
    };

    return (
        <Link
            internal
            href={tenantConfig.urls.payback}
            className="mr-3x text-secondary"
            onClick={trackClick}
            variant={LinkVariant.GreyQuiet}
            iconLeft={
                <Image
                    src={PaybackLogoSrc}
                    alt=""
                    lazyLoad={false}
                    width={18}
                    height={18}
                    fetchpriority="low"
                />
            }
        >
            <span>{intl.formatMessage(messages.payback)}</span>
        </Link>
    );
};

export { PaybackLink };
