import Axios from 'axios';

import { type RedemptionInformation } from '../../shared/types';

let controller: AbortController;

async function removeVoucherCode(voucherCode: string): Promise<RedemptionInformation> {
    if (controller !== undefined) controller.abort();
    controller = new AbortController();

    const response = await Axios.delete<RedemptionInformation>('/api/cart/vouchers', {
        params: { voucherCode },
        signal: controller.signal,
    });

    return response.data;
}

export { removeVoucherCode };
