import { defineMessages, useIntl } from 'react-intl';

import {
    ArrowIcon,
    Button,
    ButtonColor,
    ButtonShape,
    ButtonSize,
    ButtonVariant,
} from '@jsmdg/yoshi';

const messages = defineMessages({
    clearSearch: {
        defaultMessage: 'Löschen',
    },
});

type BackButtonProps = {
    readonly handler: () => void;
};

const BackButton = ({ handler }: BackButtonProps): JSX.Element => {
    const intl = useIntl();

    return (
        <Button
            size={ButtonSize.Responsive}
            variant={ButtonVariant.Ghost}
            shape={ButtonShape.Icon}
            color={ButtonColor.Grey}
            className="mr-0-5x"
            onClick={handler}
            a11yText={intl.formatMessage(messages.clearSearch)}
        >
            <ArrowIcon />
        </Button>
    );
};

export { BackButton };
