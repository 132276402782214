import Axios from 'axios';

import { type RedemptionInformation } from '../../shared/types';

let controller: AbortController;

async function addVoucherCode(
    voucherCode: string,
    voucherService: string | null,
): Promise<RedemptionInformation> {
    if (controller !== undefined) controller.abort();
    controller = new AbortController();

    const data = new URLSearchParams();
    data.append('voucherCode', voucherCode);
    if (voucherService) {
        data.append('voucherService', voucherService);
    }

    const response = await Axios.post<RedemptionInformation>('/api/cart/vouchers', data, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        signal: controller.signal,
        validateStatus(status) {
            return status >= 200 && status <= 302;
        },
    });

    return response.data;
}

export { addVoucherCode };
