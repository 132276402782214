import { FormattedMessage, useIntl } from 'react-intl';
import loadable from '@loadable/component';
import classNames from 'classnames';

import { type PublicMoneyResource } from '@jsmdg/public-money-ts/dist/nest';
import { GA4EventName, GA4FeatureCategory, trackAnalyticsEvent } from '@jsmdg/tracking';
import { Button, ButtonColor, ButtonVariant, ChevronIcon } from '@jsmdg/yoshi';
import { useActivateListItemWithDelay } from '../../hooks/useActivateListItemWithDelay';
import styles from './RedeemButton.module.scss';

const LoadableVoucherModal = loadable(async () => import('../VoucherModal/VoucherModal'));

const RedeemButtonWithBalance = ({
    layoutClassName,
    vouchersTotal,
}: {
    readonly vouchersTotal: PublicMoneyResource;
    readonly layoutClassName?: string;
}): JSX.Element => {
    const intl = useIntl();

    const {
        activeItemIndex: activeItemIndexForVoucherModal,
        resetActiveItemIndexWithDelay: hideVoucherModalWithDelay,
        setActiveItemIndexWithDelay: showVoucherModalWithDelay,
    } = useActivateListItemWithDelay();
    const showVoucherModal = activeItemIndexForVoucherModal !== -1;

    const onVoucherHover = (): void => {
        showVoucherModalWithDelay(1);
        trackAnalyticsEvent({
            category: 'Header',
            action: 'hover',
            nonInteraction: false,
            eventData: {
                eventName: GA4EventName.ClickNavigationItem,
                feature_category: GA4FeatureCategory.HeaderNavigation,
                click_element: 'Voucher uploaded',
            },
        });
    };

    const onVoucherLeave = (): void => {
        hideVoucherModalWithDelay();
    };

    const onVoucherClick = (): void => {
        showVoucherModalWithDelay(1);
    };

    return (
        <div className={layoutClassName}>
            <div className="position-relative">
                <Button
                    variant={ButtonVariant.Ghost}
                    color={ButtonColor.Brand}
                    iconRight={
                        <ChevronIcon
                            className={classNames(styles.icon, {
                                [styles.isActive]: showVoucherModal,
                            })}
                        />
                    }
                    onMouseEnter={onVoucherHover}
                    onMouseLeave={onVoucherLeave}
                    onClick={onVoucherClick}
                >
                    <span className={classNames(styles.label, 'font-brand')}>
                        <FormattedMessage defaultMessage="Guthaben" />
                    </span>
                    &nbsp;
                    <span className={classNames(styles.amount, 'font-brand fw-bold')}>
                        {intl.formatNumber(Number(vouchersTotal.amount), {
                            style: 'currency',
                            currency: vouchersTotal.currencyCode,
                        })}
                    </span>
                </Button>

                {showVoucherModal && (
                    <LoadableVoucherModal
                        mouseEnter={onVoucherClick}
                        onRequestClose={onVoucherLeave}
                    />
                )}
            </div>
        </div>
    );
};

export { RedeemButtonWithBalance };
