import { defineMessages, useIntl } from 'react-intl';

import {
    Button,
    ButtonColor,
    ButtonShape,
    ButtonSize,
    ButtonVariant,
    SearchIcon,
} from '@jsmdg/yoshi';

type ToggleSearchButtonProps = {
    readonly toggleSearch: () => void;
    readonly layoutClassName?: string;
};

const messages = defineMessages({
    openSearchWindow: {
        defaultMessage: 'Suchfenster öffnen',
    },
});

const ToggleSearchButton = ({
    layoutClassName,
    toggleSearch,
}: ToggleSearchButtonProps): JSX.Element => {
    const intl = useIntl();
    return (
        <Button
            className={layoutClassName}
            onClick={toggleSearch}
            size={ButtonSize.Small}
            variant={ButtonVariant.Ghost}
            color={ButtonColor.Dark}
            shape={ButtonShape.Icon}
            a11yText={intl.formatMessage(messages.openSearchWindow)}
        >
            <SearchIcon />
        </Button>
    );
};

export { ToggleSearchButton };
