export function getNumberOfCartItems(numberOfCartItems: number | undefined): string | null {
    if (!numberOfCartItems) {
        return null;
    }

    if (numberOfCartItems > 9) {
        return '9+';
    }

    return numberOfCartItems ? numberOfCartItems.toString() : null;
}
