import { defineMessages, useIntl } from 'react-intl';
import classnames from 'classnames';

import { PureButton, SearchIcon } from '@jsmdg/yoshi';
import styles from './ToggleSearchField.module.scss';

type ToggleSearchFieldProps = {
    readonly toggleSearch: () => void;
    readonly searchTerm?: string;
    readonly layoutClassName?: string;
};
const messages = defineMessages({
    description: {
        defaultMessage: 'Suche nach Erlebnissen, Orten...',
    },
});

const ToggleSearchField = ({
    layoutClassName,
    searchTerm,
    toggleSearch,
}: ToggleSearchFieldProps): JSX.Element => {
    const intl = useIntl();

    return (
        <PureButton
            onClick={toggleSearch}
            className={classnames(
                'd-inline-flex align-items-center w-100 py-0-5x py-0-5x px-1-5x position-relative',
                styles.searchButton,
                layoutClassName,
            )}
        >
            {searchTerm || intl.formatMessage(messages.description)}
            <div
                className={classnames(
                    'position-absolute d-flex justify-content-center align-items-center',
                    styles.buttonWrapper,
                )}
            >
                <SearchIcon className={styles.icon} />
            </div>
        </PureButton>
    );
};

export { ToggleSearchField };
