import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { trackFeatureFlagsVariation } from '@jsmdg/tracking';
import { HeaderMode } from '../../shared/enums/HeaderMode';
import { type HeaderContext } from '../types';
import { CheckoutHeader } from './CheckoutHeader/CheckoutHeader';
import { Creative } from './Creative';
import { PromoBanner } from './PromoBanner/PromoBanner';
import { RedeemHeader } from './RedeemHeader/RedeemHeader';
import { StoreHeader } from './StoreHeader/StoreHeader';
import styles from './Header.module.scss';

type HeaderProps = {
    readonly headerMode: HeaderMode;
    readonly step?: number;
    readonly isPromoBannerEnabled?: boolean;
};

const getActiveHeader = (headerMode: HeaderMode, step: number): JSX.Element => {
    switch (headerMode) {
        case HeaderMode.Discovery:
            return <StoreHeader />;
        case HeaderMode.Checkout:
            return <CheckoutHeader step={step} />;
        case HeaderMode.Booking:
            return <RedeemHeader />;
        default:
            // the next line will raise error if any case is added to HeaderMode and not mentioned in the cases!
            // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = headerMode;
            return <StoreHeader />;
    }
};

const Header = ({
    headerMode,
    isPromoBannerEnabled = false,
    step = -1,
}: HeaderProps): JSX.Element => {
    const { featureFlagsVariation } = useFragmentContext<HeaderContext>();

    useEffect(() => {
        trackFeatureFlagsVariation(featureFlagsVariation);
    }, [featureFlagsVariation]);

    return (
        <>
            {featureFlagsVariation?.webshop_restriction === 'on' && (
                <div
                    className={classNames(
                        styles.restrictionBanner,
                        'd-flex justify-content-center align-items-center text-center px-2x',
                    )}
                >
                    <FormattedMessage
                        defaultMessage="{title} Funktionen teilweise eingeschränkt!"
                        values={{
                            title: <span className="fw-semibold">Webshop in Überarbeitung:</span>,
                        }}
                    >
                        {message => <span>{message}</span>}
                    </FormattedMessage>
                </div>
            )}
            {isPromoBannerEnabled && <PromoBanner />}
            <Creative />
            {getActiveHeader(headerMode, step)}
        </>
    );
};

export { Header };
export type { HeaderProps };
