import { type Dispatch, type SetStateAction, useContext } from 'react';

import { type RedemptionInformation } from '../../shared/types';
import { RedemptionContextProvider } from '../components/RedemptionContextProvider';
import { RedemptionContext } from '../context/RedemptionContext';

const useRedemptionContext = (): {
    redemptionInformation: RedemptionInformation | null;
    setRedemptionInformation: Dispatch<SetStateAction<RedemptionInformation>>;
} => {
    const context = useContext(RedemptionContext);

    if (context === undefined) {
        throw new Error(
            `${useRedemptionContext.name} must be used within a ${RedemptionContextProvider.name}`,
        );
    }

    return context;
};

export { useRedemptionContext };
