import { useEffect, useRef, useState } from 'react';

function useActivateListItemWithDelay(): {
    activeItemIndex: number;
    setActiveItemIndexWithDelay: (item: number) => void;
    resetActiveItemIndexWithDelay: () => void;
} {
    const [activeItemIndex, setActiveItemIndex] = useState(-1);
    const openItemTimeout = useRef<ReturnType<typeof setTimeout>>();
    const closeItemTimeout = useRef<NodeJS.Timeout>();
    const isMounted = useRef(false);

    function setActiveItemIndexWithDelay(item: number): void {
        clearTimeout(closeItemTimeout.current as NodeJS.Timeout);
        openItemTimeout.current = setTimeout(() => {
            if (isMounted.current) {
                setActiveItemIndex(item);
            }
        }, 200);
    }

    function resetActiveItemIndexWithDelay(): void {
        clearTimeout(openItemTimeout.current as NodeJS.Timeout);
        closeItemTimeout.current = setTimeout(() => {
            if (isMounted.current) {
                setActiveItemIndex(-1);
            }
        }, 200);
    }

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    return {
        activeItemIndex,
        setActiveItemIndexWithDelay,
        resetActiveItemIndexWithDelay,
    };
}

export { useActivateListItemWithDelay };
