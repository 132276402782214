import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import {
    Button,
    ButtonColor,
    ButtonShape,
    ButtonSize,
    ButtonVariant,
    SearchIcon,
    useBreakpoint,
} from '@jsmdg/yoshi';
import { Breakpoint } from '@jsmdg/yoshi/dist/enums/breakpoint';
import styles from './SearchBarButton.module.scss';

const messages = defineMessages({
    searchBarButton: {
        defaultMessage: 'Suchen',
    },
});

type SearchBarButtonProps = {
    readonly handler: () => void;
};

const SearchBarButton = ({ handler }: SearchBarButtonProps): JSX.Element => {
    const intl = useIntl();
    const isDesktop = useBreakpoint(Breakpoint.MD);

    return (
        <Button
            size={ButtonSize.Small}
            shape={ButtonShape.Icon}
            className={classNames('d-flex', styles.searchBarButton)}
            onClick={handler}
            variant={ButtonVariant.Contained}
            data-testid={isDesktop ? 'search-button' : 'search-button-mobile'}
            color={ButtonColor.Brand}
            a11yText={intl.formatMessage(messages.searchBarButton)}
        >
            <SearchIcon />
        </Button>
    );
};

export { SearchBarButton };
