import { useEffect, useRef } from 'react';
import type * as SearchInsights from 'search-insights';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type HeaderContext } from '../types';

type InsightsClient = Promise<typeof SearchInsights>;

const useInsightsClient = (): React.MutableRefObject<InsightsClient | undefined> => {
    const insightsClient = useRef<InsightsClient>();
    const { algoliaConfig } = useFragmentContext<HeaderContext>();

    useEffect(() => {
        insightsClient.current = import('search-insights');
        const importSearchInsights = async (): Promise<void> => {
            const client = (await insightsClient.current)?.default;
            if (client && algoliaConfig.userToken) {
                client('init', {
                    appId: algoliaConfig.appId,
                    apiKey: algoliaConfig.searchKey,
                });
                client('setUserToken', algoliaConfig.userToken);
            }
        };

        // eslint-disable-next-line no-void
        void importSearchInsights();
    }, [algoliaConfig]);

    return insightsClient;
};

export { useInsightsClient };
