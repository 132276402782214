import { useEffect, useState } from 'react';

import { useIntersection } from './useIntersection';

export function useImpressionTracking(
    trackingCallback: () => void,
): (element: HTMLAnchorElement | HTMLDivElement | null) => void {
    const [isInViewport, setIntersectionTrackedElement] = useIntersection({
        threshold: 0.5,
    });
    const [didTrackImpression, setDidTrackImpression] = useState(false);

    useEffect(() => {
        if (isInViewport && !didTrackImpression) {
            trackingCallback();
            setDidTrackImpression(true);
        }
    }, [didTrackImpression, isInViewport, trackingCallback]);

    return setIntersectionTrackedElement;
}
