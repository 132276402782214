import { useCallback, useEffect, useState } from 'react';

import { logToSentry, useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { trackCartItemCount, trackCartPreviewRebuild } from '@jsmdg/tracking';
import { type CartPreview } from '../../shared/types';

let cartPromise: Promise<CartPreview> | null = null;

function useCart(): CartPreview | null {
    const [cart, setCart] = useState<CartPreview | null>(null);
    const { fetchCart }: { fetchCart: () => Promise<CartPreview> } = useFragmentContext();

    const fetch = useCallback(async (): Promise<void> => {
        /*
         * if users have cookie disabled, it doesn't make sense to fetch the cart which is session related
         */
        if (!cartPromise && navigator.cookieEnabled) {
            cartPromise = fetchCart();

            const fetchedCart = await cartPromise;
            if (fetchedCart.itemsCount > 0 && fetchedCart.rebuilt && fetchedCart.trackingLabel) {
                trackCartPreviewRebuild(fetchedCart.trackingLabel);
            }

            trackCartItemCount(fetchedCart.itemsCount);
        }

        setCart(await cartPromise);
    }, [fetchCart]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetch();
            } catch (error) {
                logToSentry(error as Error);
            }
        };

        fetchData();
    }, [cart, fetch]);

    return cart;
}

export { useCart };
