import {
    ClickEventLabel,
    EventCategory,
    GA4EventName,
    GA4FeatureCategory,
    trackAnalyticsEvent,
} from '@jsmdg/tracking';
import { PhoneIcon, PureButton } from '@jsmdg/yoshi';
import styles from './CallButton.module.scss';

type CallButtonProps = {
    readonly number: string;
    readonly onClick: () => void;
};

const CallButton = ({ number, onClick }: CallButtonProps): JSX.Element => {
    const onButtonClick = async (): Promise<void> => {
        await window.yieldToMainThread();
        trackAnalyticsEvent({
            category: EventCategory.Navigation,
            action: 'Header',
            label: ClickEventLabel.CONTACT,
            eventData: {
                eventName: GA4EventName.ClickNavigationItem,
                feature_category: GA4FeatureCategory.HeaderNavigation,
                click_element: 'Phone number',
                click_text: number,
            },
        });
        onClick();
    };

    return (
        <PureButton
            onClick={onButtonClick}
            className={`d-flex flex-row align-items-center fw-regular w-auto h-auto p-0-5x ${styles.callButtonContainer}`}
            data-testid={`${number}-button`}
        >
            <PhoneIcon className="mr-0-5x" size={16} />
            {number}
        </PureButton>
    );
};

export { CallButton };
