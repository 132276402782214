import {
    type Dispatch,
    type MutableRefObject,
    type SetStateAction,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { debounce } from '../helper/debounce';

const createDebouncedStateUpdater = <T>(
    timeout: number,
): ((
    value: SetStateAction<T>,
    updateValue: Dispatch<SetStateAction<T>>,
    mounted: MutableRefObject<boolean>,
) => void) =>
    debounce(
        (
            value: SetStateAction<T>,
            updateValue: Dispatch<SetStateAction<T>>,
            mounted: MutableRefObject<boolean>,
        ): void => {
            if (mounted.current) {
                updateValue(value);
            }
        },
        timeout,
    );

export const useDebouncedState = <T>(
    initialState: T | (() => T),
    time = 500,
): [T, Dispatch<SetStateAction<T>>] => {
    const [value, updateValue] = useState<T>(initialState);
    const mounted = useRef(false);

    const debouncedStateUpdate = useMemo(() => createDebouncedStateUpdater<T>(time), [time]);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    return [
        value,
        (v: SetStateAction<T>): void => {
            debouncedStateUpdate(v, updateValue, mounted);
        },
    ];
};
