import { useRef } from 'react';
import { useIsSSR } from 'react-aria';
import classNames from 'classnames';

import styles from './Backdrop.module.scss';

type BackdropProps = {
    readonly showBackdrop: boolean;
};

const Backdrop = ({ showBackdrop }: BackdropProps): JSX.Element => {
    const backdropRef = useRef<HTMLDivElement>(null);
    const isSSR = useIsSSR();

    return (
        <div
            data-testid="backdrop"
            className={classNames(
                'w-100 position-absolute',
                styles.backdrop,
                showBackdrop ? styles.show : styles.hide,
            )}
            ref={backdropRef}
            style={{
                height: isSSR
                    ? '0'
                    : `calc(${document.body?.clientHeight || 0}px - ${
                          backdropRef.current?.offsetTop || 0
                      }px)`,
            }}
        />
    );
};

export { Backdrop };
