import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import {
    BurgerMenuIcon,
    Button,
    ButtonColor,
    ButtonShape,
    ButtonSize,
    ButtonVariant,
} from '@jsmdg/yoshi';
import styles from '../BurgerNavigation.module.scss';

const messages = defineMessages({
    openMenu: {
        defaultMessage: 'Hauptmenü öffnen',
    },
});

export const BurgerNavigationFallback = ({
    layoutClassName,
}: {
    readonly layoutClassName?: string;
}): JSX.Element => {
    const Intl = useIntl();

    return (
        <div className={classNames(styles.mobileNav, layoutClassName)}>
            <Button
                size={ButtonSize.Responsive}
                variant={ButtonVariant.Ghost}
                color={ButtonColor.Dark}
                shape={ButtonShape.Icon}
                a11yText={Intl.formatMessage(messages.openMenu)}
                tabIndex={-1}
            >
                <BurgerMenuIcon />
            </Button>
        </div>
    );
};
