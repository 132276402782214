import { TenantEnum } from '@jsmdg/tenancy';
import { jsCategories, mdCategories } from '../__fixtures__/categoryItems';
import { type TenantConfig } from '../shared/types/tenantConfig';

const jsDeTenantConfig: TenantConfig = {
    allExperiencesLabel: 'Alle Erlebnisse entdecken',
    allExperiencesPath: '/l/79ifa',
    category: 'Erlebniskategorien',
    displayJudgement: 'urteilsveroeffentlichung',
    onlineExperienceOptions: ['Landesweit', 'Weltweit'],
    organisationAddress: {
        '@type': 'PostalAddress',
        streetAddress: 'Mühldorfstraße 8',
        postalCode: '81671',
        addressLocality: 'München',
        addressCountry: 'Deutschland',
    },
    organisationAvailableLanguages: [
        {
            '@type': 'Language',
            name: 'German',
            alternateName: 'de',
        },
        {
            '@type': 'Language',
            name: 'English',
            alternateName: 'en',
        },
    ],
    organisationFounder: {
        '@type': 'Person',
        name: 'Jochen Schweizer',
        sameAs: 'https://www.instagram.com/jochenschweizer/',
    },
    organisationName: 'Jochen Schweizer GmbH',
    uspClaim: 'Beste Auswahl an einzigartigen Erlebnissen',
    uspValidityText: 'Der Marktführer mit über 1 Million Kunden',
    categoryItems: jsCategories,
    partnerPortalUrl: 'https://partner.jsmd-group.com/',
};

const MD_DEFAULT = {
    ...jsDeTenantConfig,
    organisationName: 'mydays GmbH',
    uspClaim: 'Mehr als 8.000 ausgewählte Erlebnisse',
    uspValidityText: 'Flexible Gutscheine für Zeit, Ort und Erlebnis',
    allExperiencesPath: '/l/o7trc',
    categoryItems: mdCategories,
};

const tenantConfigMap: Record<TenantEnum, TenantConfig> = {
    [TenantEnum.JS_DE]: jsDeTenantConfig,
    [TenantEnum.JS_AT]: jsDeTenantConfig,
    [TenantEnum.MD_DE]: MD_DEFAULT,
    [TenantEnum.MD_AT]: MD_DEFAULT,
    [TenantEnum.MD_CH]: MD_DEFAULT,
    [TenantEnum.JS_NOW_DE]: jsDeTenantConfig,
    [TenantEnum.JS_TEST]: jsDeTenantConfig,
    [TenantEnum.MD_TEST]: { ...jsDeTenantConfig, categoryItems: mdCategories },
};

const getTenantConfig = (tenant: TenantEnum): TenantConfig => {
    return tenantConfigMap[tenant] || tenantConfigMap[TenantEnum.JS_DE];
};

export { getTenantConfig };
