import { CategoryIcon } from '../client/enums/categoryIcon';
import { type CategoryItem } from '../shared/types';

export const jsCategories: CategoryItem[] = [
    {
        title: 'Geschenkboxen',
        url: '/geschenke/geschenkbox/l/svfxg',
        icon: CategoryIcon.Giftbox,
    },
    {
        title: 'Wertgutscheine',
        url: '/geschenkgutscheine/Wertgutscheine/l/gwtim',
        icon: CategoryIcon.ValueVoucher,
    },
    {
        title: 'Reisen',
        url: '/kurzurlaub/l/d48wi',
        icon: CategoryIcon.Travel,
    },
    {
        title: 'Fliegen & Fallen',
        url: '/fliegen-fallen/l/p3if5',
        icon: CategoryIcon.Flying,
    },
    {
        title: 'Motorpower',
        url: '/motorpower/l/p6jmw',
        icon: CategoryIcon.Motorpower,
    },
    {
        title: 'Essen & Trinken',
        url: '/essen-trinken/l/9net3',
        icon: CategoryIcon.Dinner,
    },
    {
        title: 'Wassersport',
        url: '/wassersport/l/ptauo',
        icon: CategoryIcon.WaterSports,
    },
];

export const mdCategories: CategoryItem[] = [
    {
        title: 'Geschenkboxen',
        url: '/geschenkboxen/l/m3jx4',
        icon: CategoryIcon.Giftbox,
    },
    {
        title: 'Wertgutscheine',
        url: '/wertgutscheine/l/z64ce',
        icon: CategoryIcon.ValueVoucher,
    },
    {
        title: 'Reisen',
        url: '/kurzurlaub/l/g6ukx',
        icon: CategoryIcon.Travel,
    },
    {
        title: 'Essen & Trinken',
        url: '/kulinarische-geschenke/l/fg1m5',
        icon: CategoryIcon.Dinner,
    },
    {
        title: 'Fliegen & Fallen',
        url: '/fliegen/l/3fi1s',
        icon: CategoryIcon.Flying,
    },
    {
        title: 'Motorpower',
        url: '/motorsport/l/3r26w',
        icon: CategoryIcon.Motorpower,
    },
    {
        title: 'Wellness',
        url: '/wellness/l/s4mj8',
        icon: CategoryIcon.Wellness,
    },
];
