import { useMemo } from 'react';
import { createBrowserLocalStorageCache } from '@algolia/cache-browser-local-storage';
import { createFallbackableCache } from '@algolia/cache-common';
import { createInMemoryCache } from '@algolia/cache-in-memory';
import algoliasearch, { type SearchClient } from 'algoliasearch/lite';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type HeaderContext } from '../types';

export const useAlgoliaClient = (): SearchClient => {
    const { algoliaConfig } = useFragmentContext<HeaderContext>();
    return useMemo(
        () =>
            algoliasearch(algoliaConfig.appId, algoliaConfig.searchKey, {
                responsesCache: createFallbackableCache({
                    caches: [
                        createBrowserLocalStorageCache({
                            key: 'algolia-responses',
                            timeToLive: 900, // 15min
                        }),
                        createInMemoryCache(),
                    ],
                }),

                // Caches Promises with the same request payload
                requestsCache: createFallbackableCache({
                    caches: [
                        createBrowserLocalStorageCache({
                            key: 'algolia-requests',
                            timeToLive: 900, // 15min
                        }),
                        createInMemoryCache(),
                    ],
                }),
            }),
        [algoliaConfig.appId, algoliaConfig.searchKey],
    );
};
