const focusAndOpenKeyboard = (el: HTMLInputElement, timeout: number = 100): void => {
    const providedTimeout = timeout;
    if (el) {
        // Align temp input element approx. to be where the input element is
        const tempEl = document.createElement('input');
        tempEl.style.position = 'absolute';
        tempEl.style.top = `${el.offsetTop + 7}px`;
        tempEl.style.left = `${el.offsetLeft}px`;
        tempEl.style.height = '0';
        tempEl.style.opacity = '0';
        // Put this temp element as a child of the page <body> and focus on it
        document.body.appendChild(tempEl);
        tempEl.focus();

        // The keyboard is open. Now do a delayed focus on the target element
        setTimeout(() => {
            el.focus();
            el.click();
            // Remove the temp element
            document.body.removeChild(tempEl);
        }, providedTimeout);
    }
};

export { focusAndOpenKeyboard };
