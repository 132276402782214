import { useState } from 'react';
import loadable from '@loadable/component';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Tenant } from '@jsmdg/tenancy';
import { type HeaderContext } from '../../types';
import { CheckoutSteps } from '../CheckoutSteps/CheckoutSteps';
import { CheckoutStepsWrapper } from '../CheckoutSteps/CheckoutStepsWrapper';
import { Logo } from '../Logo';
import { CallButton } from '../TopHeader/CallButton';
import styles from './CheckoutHeader.module.scss';

const LoadableContactModal = loadable(async () => import('../TopHeader/ContactModal'));

type CheckoutHeaderProps = {
    readonly step: number;
};

const CheckoutHeader = ({ step }: CheckoutHeaderProps): JSX.Element => {
    const { tenant, tenantConfig }: HeaderContext = useFragmentContext();
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);

    return (
        <>
            <header className={classNames(styles.checkoutHeader)}>
                <div className="d-flex justify-content-center justify-content-md-between container">
                    <Logo className="my-2x" href="/" isOnMdBreakpoint />
                    <div
                        className={classNames(
                            'd-none d-md-flex mt-md-2x align-items-start',
                            styles.checkoutHeaderContact,
                        )}
                    >
                        <CallButton
                            number={tenantConfig.contactInfo.phone.label}
                            onClick={() => setIsContactModalOpen(true)}
                        />
                    </div>
                </div>
                {step > 0 && (
                    <div className="d-flex justify-content-center justify-content-md-start">
                        <CheckoutStepsWrapper>
                            <CheckoutSteps step={step} />
                        </CheckoutStepsWrapper>
                    </div>
                )}
            </header>
            {isContactModalOpen && (
                <LoadableContactModal
                    onRequestClose={() => setIsContactModalOpen(false)}
                    phoneLabel={tenantConfig.contactInfo.phone.label}
                    phoneNumber={tenantConfig.contactInfo.phone.number}
                    phoneCountry={Tenant[tenant].getCountryCode()}
                    isOpen={isContactModalOpen}
                />
            )}
        </>
    );
};

export { CheckoutHeader };
